export default {
  apiBaseUrl: 'https://api.omark.io/api/v1',
  logBaseUrl: 'https://log.omark.io/api/v1',
  appId: '60712867-e3a3-4dd9-9e10-0b754489c175',
  enableMock: false,

  // Various
  requireVersionCheck: true,
  androidPackageId: 'srl.eureka.piclink',
  iTunesAppId: '1533058257',

  // Login
  enableLogin: true,
  loginSignupUrl: null,
  loginRecoverUrl: 'https://elearning.eureka.srl/wp-login.php?action=lostpassword&redirect_to=https%3A%2F%2Felearning.eureka.srl%2Flogin%2F%3Fmessage%3Dcheckmail',

  // Home
  homeNoProjectsUrl: null,
  homeScanProjectEnabled: false,

  // Scan
  enableFlashToggle: false,
};
