<template>
  <DefaultLayout :header="!isLoading && logged" @logout="logout">
    <Loading v-if="isLoading" />
    <Login v-else-if="!logged" @logged="setLogged" />
  </DefaultLayout>
</template>

<script>

import $appConfig from '@/config/appConfig.js';
import DefaultLayout from './layouts/DefaultLayout.vue';
import Loading from './components/Loading.vue';
import Login from './pages/Login.vue';

export default {
  name: 'app',
  components: {
    DefaultLayout,
    Loading,
    Login,
  },
  data () {
    return {
      loading: true,

      logged: false,
    };
  },
  computed: {
    isLoading () {
      return this.loading || !this.$route?.name;
    },
  },
  methods: {
    computeHeight () {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    setLogged () {
      this.logged = true;
    },
    logout () {
      this.$storage.set('username', null);
      this.$storage.set('token', null);
      this.$storage.set('projects', []);

      this.logged = false;
    },
  },
  async mounted () {
    window.addEventListener('resize', this.computeHeight);
    this.computeHeight();

    this.logged = !!this.$storage.get('username', null);
    if (this.logged) {
      this.$http.post(`${$appConfig.apiBaseUrl}/apps/${$appConfig.appId}/refresh`, {
        token: this.$storage.get('token'),
      })
        .then(res => {
          this.$storage.set('username', res.data?.user_id);
          this.$storage.set('token', res.data?.token);
          this.$storage.set('projects', res.data?.projects || []);
        })
        .catch(this.logout)
        .finally(() => {
          this.loading = false;
        })
      ;

      return;
    }

    this.loading = false;
  },
};

</script>

<style lang="scss"></style>
