<template>
  <main class="page page-fixed">
    <div class="page__content p-4 h-100 d-flex flex-column align-items-center justify-content-around">
      <h1 class="text-center pt-4 font-weight-bold">{{ $texts.pages.login.welcomeTitle }}</h1>
      <p class="text-center">
        {{ $texts.pages.login.welcomeText1 }}
        <br>
        {{ $texts.pages.login.welcomeText2 }}
      </p>
      <img class="flex-shrink-1 mb-3" style="height: 200px" :src="require('@/assets/images/pages/login.svg')" alt="" aria-hidden="true">

      <form @submit.prevent="doLogin">
        <b-form-input class="mb-4" v-model="username" :placeholder="$texts.pages.login.usernameHint" :disabled="loading"></b-form-input>
        <b-form-input class="mb-4" v-model="password" type="password" :placeholder="$texts.pages.login.passwordHint" :disabled="loading"></b-form-input>

        <b-button type="submit" variant="primary" class="shadow-lg w-100" :disabled="loading">
          <b-spinner v-if="loading" small></b-spinner>
          {{ $texts.pages.login.loginCTA }}
        </b-button>

        <p v-if="feedback" class="text-center text-danger mt-3">
          {{ feedback }}
        </p>
      </form>

      <div v-if="$config.loginSignupUrl && $config.loginRecoverUrl" class="mt-3 mb-4 d-flex flex-row justify-content-between">
        <a v-if="$config.loginSignupUrl" class="btn btn-link" :href="$config.loginSignupUrl" target="_blank">
          {{ $texts.pages.login.signup }}
        </a>
        <a v-if="$config.loginRecoverUrl" class="btn btn-link" :href="$config.loginRecoverUrl" target="_blank">
          {{ $texts.pages.login.recoverPassword }}
        </a>
      </div>
      <div v-else class="mt-3 mb-4 d-flex flex-row justify-content-center">
        <a v-if="$config.loginSignupUrl" class="text-dark font-weight-bold" :href="$config.loginSignupUrl" target="_blank">
          {{ $texts.pages.login.signup }}
        </a>
        <a v-if="$config.loginRecoverUrl" class="text-dark font-weight-bold" :href="$config.loginRecoverUrl" target="_blank">
          {{ $texts.pages.login.recoverPassword }}
        </a>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  data () {
    return {
      loading: false,

      username: null,
      password: null,

      feedback: null,
    };
  },
  methods: {
    resetFeedback () {
      this.feedback = null;
    },
    doLogin () {
      this.loading = true;
      this.resetFeedback();

      if (!this.username) {
        this.feedback = this.$texts.pages.login.usernameRequired;
        this.loading = false;

        return;
      }

      if (!this.password) {
        this.feedback = this.$texts.pages.login.passwordRequired;
        this.loading = false;

        return;
      }

      this.$http.post(`${this.$config.apiBaseUrl}/apps/${this.$config.appId}/login`, {
        username: this.username,
        password: this.password,
      })
        .then(res => {
          this.$storage.set('username', res.data?.user_id);
          this.$storage.set('token', res.data?.token);
          this.$storage.set('projects', res.data?.projects || []);

          this.$emit('logged');

          this.username = null;
          this.password = null;
        })
        .catch(() => {
          this.feedback = this.$texts.pages.login.loginFailed;
        })
        .finally(() => {
          this.loading = false;
        })
      ;
    },
  },
};

</script>
